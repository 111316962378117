
.login-bg[data-v-0cdb31aa] {
		background: rgba(0,0,0,.3);
		background-size: cover;
		height: 100%;
}
.lockscreen-wrap[data-v-0cdb31aa] {
		position: relative;
		height: 100%;
		width: 100%;
}
.ibox-content[data-v-0cdb31aa] {
		background-color: #fff;
		padding: 25px 50px 30px 50px;
		clear: both;
		border-radius: 6px;
		width: 300px;
}
.full-width[data-v-0cdb31aa] {
		width: 100% !important;
}
.dialog-footer[data-v-0cdb31aa] {
  padding: 50px 0px 20px;
  box-sizing: border-box;
}

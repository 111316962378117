
.login-bg[data-v-07d99d3a] {
  background: url(../img/login-bg.d99dea7e.jpg) no-repeat center top
    fixed;
  background-size: cover;
  height: 100%;
}
.login-wrap[data-v-07d99d3a] {
  background-color: rgba(33, 32, 42, 0.44);
  position: relative;
  height: 100%;
  width: 100%;
}
.login-header[data-v-07d99d3a] {
  color: #fff;
  font-size: 34px;
  margin-bottom: 20px;
  letter-spacing: 1px;
  font-weight: 600;
  margin-top: 10%;
}
.ibox-content[data-v-07d99d3a] {
  background-color: #fff;
  padding: 40px 30px 40px 30px;
  clear: both;
  border-radius: 6px;
  width: 320px;
}
.full-width[data-v-07d99d3a] {
  width: 100% !important;
}
.forget-pwd[data-v-07d99d3a] {
  margin-top: -16px;
  font-size: 14px;
  color: #999;
  text-align: right;
}
.forget-pwd span[data-v-07d99d3a] {
  cursor: pointer;
}
.forget-pwd span[data-v-07d99d3a]:hover {
  text-decoration: underline;
}
.box-register[data-v-07d99d3a] {
  font-size: 14px;
  color: #999;
  text-align: center;
}
.box-register span[data-v-07d99d3a] {
  cursor: pointer;
  color: #409eff;
}
.box-register span[data-v-07d99d3a]:hover {
  text-decoration: underline;
}

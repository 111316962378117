
.login-bg[data-v-6e19685a] {
	background-position: center top;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	background-image: url(../img/login-bg.d99dea7e.jpg);
	height: 100%;
}
.lockscreen-wrap[data-v-6e19685a] {
	background-color: rgba(33, 32, 42, 0.44);
	position: relative;
	height: 100%;
	width: 100%;
}
.ibox-content[data-v-6e19685a] {
	background-color: #fff;
	padding: 25px 50px 30px 50px;
	clear: both;
	border-radius: 6px;
	width: 300px;
}
.full-width[data-v-6e19685a] {
	width: 100% !important;
}
.title[data-v-6e19685a] {
	width: 100%;
	text-align: center;
	height: 60px;
}


.header[data-v-1846491e] {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 46px;
  font-size: 18px;
  color: #333333;
  background-color: #fff;
}
.header-left[data-v-1846491e] {
  float: left;
}
.collapse-btn[data-v-1846491e] {
  /* float: left; */
  padding-left: 20px;
  cursor: pointer;
  line-height: 46px;
  font-size: 20px;
}
.header .logo[data-v-1846491e] {
  float: left;
  width: 250px;
  line-height: 46px;
}
.header-right[data-v-1846491e] {
  float: right;
  padding-right: 10px;
}
.header-user-con[data-v-1846491e] {
  display: flex;
  height: 46px;
  align-items: center;
}
.btn-fullscreen[data-v-1846491e] {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}
.btn-bell[data-v-1846491e],
.btn-fullscreen[data-v-1846491e] {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
  margin: 0 5px;
  line-height: 30px;
}
.btn-bell-badge[data-v-1846491e] {
  position: absolute;
  right: 8px;
  top: 4px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
  color: #333333;
}
.user-name[data-v-1846491e] {
  margin-left: 10px;
}
.user-avator[data-v-1846491e] {
  margin-left: 13px;
}
.user-avator img[data-v-1846491e] {
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.el-dropdown-link[data-v-1846491e] {
  color: #333333;
  cursor: pointer;
}
.el-dropdown-menu__item[data-v-1846491e] {
  text-align: center;
}
.line[data-v-1846491e] {
  width: 1px;
  height: 27px;
  background-color: #eeeeee;
  margin: 0 5px;
}
.lockScreen[data-v-1846491e] {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
}
.change-icon[data-v-1846491e] {
  width: 18px;
  height: 18px;
  margin-top: -3px;
}

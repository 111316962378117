
.error-page[data-v-f244ed02]{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #f3f3f3;
    box-sizing: border-box;
}
.error-code[data-v-f244ed02]{
    line-height: 1;
    font-size: 150px;
    font-weight: bolder;
    color: #2d8cf0;
}
.error-code span[data-v-f244ed02]{
    color: #00a854;
}
.error-desc[data-v-f244ed02]{
    font-size: 30px;
    color: #777;
}
.error-handle[data-v-f244ed02]{
    margin-top: 30px;
    /* padding-bottom: 200px; */
}
.error-btn[data-v-f244ed02]{
    margin-right: 100px;
}


.sidebar[data-v-d7d986ca] {
  width: 200px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #001529;
}
.side-menu[data-v-d7d986ca] {
  width: 100%;
  overflow-y: auto;
  height: calc(100% - 73px);
  scrollbar-width: none;   /* firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}
.side-menu[data-v-d7d986ca]::-webkit-scrollbar {
  width: 0px;
}
.sidebar-el-menu[data-v-d7d986ca]:not(.el-menu--collapse) {
  width: 200px;
}
.sidebar > ul[data-v-d7d986ca] {
  height: 100%;
}
.side-logo[data-v-d7d986ca] {
  height: 73px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(250, 250, 250, 0.2);
}
.logo[data-v-d7d986ca] {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 50%;
}
.title-name[data-v-d7d986ca] {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
  line-height: 19px;
}

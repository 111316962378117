
/* .form-wrap {
	  padding-left: 80px;
  } */
.statisticsContent[data-v-bedbf6a2] {
  display: flex;
  flex-direction: column;
}
.el-form-item[data-v-bedbf6a2] {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
}
.dataContent[data-v-bedbf6a2] {
  width: 90%;
}
.list-content[data-v-bedbf6a2] {
  display: block;
  position: relative;
  background-color: white;
  margin-top: 10px;
  width: 100%;
}
.list-content h2[data-v-bedbf6a2] {
  font-size: 25px;
  font-weight: bold;
  padding: 20px 20px;
  color: black;
}
.data-list li[data-v-bedbf6a2] {
  display: inline-block;
  width: 30%;
  margin: 10px 10px 10px 1%;
}
.data-item[data-v-bedbf6a2] {
  width: 100%;
  min-height: 120px;
  background-color: #f7f7f7;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.menuitem_num[data-v-bedbf6a2] {
  font-weight: bold;
  padding: 0px 10px 5px 10px;
  font-size: 30px;
  color: #409eff;
}
.menuitem_name[data-v-bedbf6a2] {
  padding: 5px 10px 0px 10px;
  font-size: 15px;
  color: #409eff;
}
.leaderboardContent[data-v-bedbf6a2] {
  width: 23%;
  margin-top: 10px;
}
.leaderboardContent h2[data-v-bedbf6a2] {
  font-size: 25px;
  font-weight: bold;
  padding: 20px 20px;
  color: black;
}
.leaderboardContent ul li[data-v-bedbf6a2] {
  width: 100%;
  line-height: 43.1px;
  margin-top: 5px;
  padding: 0px 10px;
  background-color: white;
}


/* .tags .tag-box {
		scrollbar-width: none;
		/* firefox */
/* -ms-overflow-style: none; */
/* IE 10+ */
/* } */
.tags {
  position: relative;
  height: 32px;
  overflow: hidden;
  /* padding: 0 16px; */
  padding-left: 5px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e5e5e5;
  background: #fff;
  margin-bottom: 10px;
  box-sizing: border-box;
  flex: none;
}
.tags .tag-box {
  /* width: 100%; */
  box-sizing: border-box;
  white-space: nowrap;
  position: relative;
  display: flex;
  flex: auto;
  align-self: stretch;
  transform: translate(0);
}
.tags .tag-box ul {
  position: absolute;
  display: flex;
  box-sizing: border-box;
  transition: transform 0.3s;
}
.tags .tag-box::-webkit-scrollbar {
  display: none;
}
.tags-li {
  margin-right: 3px;
  border-radius: 3px;
  font-size: 12px;
  overflow: hidden;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  border: 1px solid #d9d9d9;
  background: #fff;
  padding: 0 5px 0 10px;
  vertical-align: middle;
  color: #323232;
  /* -webkit-transition: all 0.1s ease-in;
		-moz-transition: all 0.1s ease-in;
		transition: all 0.1s ease-in; */
  display: inline-block;
}
.tags-li-title {
  float: left;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  color: #323232;
}
.tags-li.active .tags-li-title {
  color: #fff;
}
.tags-li.active {
  background-color: #0960bd;
  color: #fff;
}
.tags-li:not(.active) span {
  display: none;
}
.tags-li span:hover {
  transform: scale(1.4);
}
.tags-li:not(.active):hover {
  background: #fff;
}
.tags-li:not(.active):hover span {
  display: inline-block !important;
  color: #0960bd;
}
.tags-li:not(.active):hover .tags-li-title {
  color: #0960bd;
}
.tags-close-box {
  /* position: absolute;
		right: 0;
		top: 0; */
  box-sizing: border-box;
  /* padding-top: 1px;
		text-align: center;
		width: 110px;
		height: 30px; */
  height: 100%;
  background: #fff;
  box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);
  z-index: 10;
  flex: none;
}

/* .tag-linkage {
		width: 30px;
		height: 30px;
		background: #ffffff;
		line-height: 30px;
		text-align: center;
		cursor: pointer;
	} */
.tags-close-box .el-dropdown,
.tags-close-box button {
  padding: 0;
  border: 0;
  height: 100%;
  background-color: #fff;
  border-radius: 0;
}
.tags-close-box .more-btn,
.tags-close-box .refresh-btn,
.tags-close-box .close-btn {
  width: 32px;
  height: 100%;
  border-right: 1px solid #eee;
}
.tags-close-box .full-screen-btn {
  width: 32px;
  height: 100%;
  border-right: 0;
}
.full-screen-btn img {
  width: 15px;
  height: 12px;
}
.tags-close-box button i {
  color: #646464;
  font-size: 14px;
}
.tags-close-box button:hover {
  background-color: #fff;
}
.tags-close-box button:hover i {
  color: #000;
  font-weight: 600;
}
